<template>
  <section>
    <div class="content-header">
      <h2>Works cited </h2>
    </div>
    <div class="content-wrapper">
      <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared. </p>
      <ul>
        <li class="pb-3">Abdel-Magied, Y. (2014, December 14). <em>What does my headscarf mean to you?</em> [Video]. TED Talks. <a href="https://www.ted.com/talks/yassmin_abdel_magied_what_does_my_headscarf_mean_to_you/transcript?utm_source=tedcomshare&utm_medium=email&utm_campaign=tedspread " target="_blank">www.ted.com/talks/yassmin_abdel_magied_what_does_my_headscarf_mean_to_you/transcript?utm_source=tedcomshare&utm_medium=email&utm_campaign=tedspread </a></li>
        <li class="pb-3">Seibert, Scott & Kraimer, Maria & Heslin, Peter. (2016). Developing career resilience and adaptability. Organizational Dynamics. <a href="https://www.sciencedirect.com/science/article/abs/pii/S0090261616300699?via%3Dihub" target="_blank">https://www.sciencedirect.com/science/article/abs/pii/S0090261616300699?via%3Dihub</a></li>
        <li class="pb-3">Pardy, L. (2020). <em>Career Mapping: Expanding Innovative Pathways for Employment Security: Our Response to Research</em> [Slides]. </li>
        <li class="pb-3"><em>Cannexus20 Handouts & PowerPoints – Cannexus22</em>. (2020). Cannexus22. <a href="https://cannexus.ceric.ca/cannexus20-handouts-powerpoints/?limit=5&q=career&catid=23" target="_blank">https://cannexus.ceric.ca/cannexus20-handouts-powerpoints/?limit=5&q=career&catid=23</a></li>
        <li class="pb-3">Grant, A. (2019, April 2). Stop Asking Kids What They Want to Be When They Grow Up. <em>The New York Times</em>. <a href="https://www.nytimes.com/2019/04/01/smarter-living/stop-asking-kids-what-they-want-to-be-when-they-grow-up.html" target="_blank">www.nytimes.com/2019/04/01/smarter-living/stop-asking-kids-what-they-want-to-be-when-they-grow-up.html</a> </li>
        <li class="pb-3">Apple Podcasts. (2019, March 25). <em>The Perils of Following Your Career Passion</em>.<br> <a href="https://podcasts.apple.com/us/podcast/the-perils-of-following-your-career-passion/id1346314086?i=1000433418434" target="_blank">podcasts.apple.com/us/podcast/the-perils-of-following-your-career-passion/id1346314086?i=1000433418434</a> </li>
        <li>Turczynski, B. (2021, April 14). <em>2021 HR Statistics: Job Search, Hiring, Recruiting & Interviews</em>. Zety.<br> <a href="https://zety.com/blog/hr-statistics" target="_blank">zety.com/blog/hr-statistics</a> </li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '06',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
